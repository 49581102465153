<template>
  <v-container fluid>
    <v-row>
        <h1 class="page-title mt-4 mb-5">Transaksi Harian</h1>
         
    </v-row>
    <center><h5 class="">Total Transaksi : {{total.toLocaleString()}}</h5></center>
    <div class="tables-basic">
      <v-row>
        <v-col cols=12>
               <v-col cols=4 lg=4 >
                 <v-row >
                    <v-menu
                          ref="date"
                          v-model="date"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                      >
                          <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="pickedDate"
                              label="Tanggal"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              persistent-hint
                              @blur="date = parseDate(dateFormatted)"
                          ></v-text-field>
                          </template>
                          <v-date-picker
                          v-model="pickedDate"
                          @input="fromDate = false"
                          >
                          </v-date-picker>
                    </v-menu>
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="filter"
                    >
                      Filter
                    </v-btn>
                  </v-row>
                  
                  
                </v-col>

            <div class="tables-basic" >
                <v-row>
                    <v-col cols=12>
                    <v-card>
                        <v-card-title>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                        </v-card-title>
                        <v-data-table
                        :headers="headers"
                        :items="dataTable"
                        :search="search"
                        >
                        <template v-slot:item.penarikan="{ item }">
                            
                            {{ item.penarikan.toLocaleString() }}
                        </template>
                        <template v-slot:item.setoran="{ item }">
                            
                            {{ item.setoran.toLocaleString() }}
                        </template>
                        </v-data-table>
                    </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-col>
      </v-row>
    </div>
 
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
export default {
  name: 'transaksiall',
  data () {
    return {
        search: '',
        headers: [
          { text: 'Tanggal',  value: 'tanggal' },
          { text: 'Kolektor', value: 'kolektor' },
          { text: 'Jenis', value: 'jenis' },
          { text: 'No Nota', value: 'notransaksi' },
          { text: 'No Transaksi', value: 'nonota' },
          { text: 'Nasabah', value: 'nasabah' },
          { text: 'Setoran', align: 'right',  value: 'setoran'  },
          { text: 'Penarikan',align: 'right',  value: 'penarikan' },
        ],
        row: '',
        isShowing:false,
        dataTable:[],
         pickedDate: new Date().toISOString().substr(0, 10),
         date:'',
        total:0,
    }
  },
  computed: {
    ...mapGetters({
          user : 'auth/user',
    }),
  },
  methods: {
    ...mapActions({
        setAlert : 'alert/set',
        setAuth : 'auth/set',
    }),
     
    filter(){
      let enddate = new Date(this.pickedDate)
      let formData = {
      'id' : '',
      'tgl' : moment(enddate).format("YYYY-MM-DD")
      // 'row': this.row ,
    }

      this.axios.post('/transaksiAll', formData)
      .then((response) => {
        let { data } = response.data
        this.total=response.data.total
        console.log('total', this.total)
        this.dataTable = data;
      })
      .catch((error) => {
        let responses = error.response
        this.setAlert({
          status : true,
          color : 'error',
          text : responses.data.message,
        })
      })
    }
    
  },
  mounted: function() {
    let now = new Date()
    let formData = {
      'id' : '',
      'tgl' : moment(now).format("YYYY-MM-DD")
      // 'row': this.row ,
    }

    this.axios.post('/transaksiAll', formData)
    .then((response) => {
      let { data } = response.data
       this.total=response.data.total
      console.log('total', this.total)
      this.dataTable = data;
    })
    .catch((error) => {
      let responses = error.response
      this.setAlert({
        status : true,
        color : 'error',
        text : responses.data.message,
      })
    })

     

  }
}

</script>