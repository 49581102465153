<template>
  <v-container fluid>
    <v-row>
        <h1 class="page-title mt-4 mb-5">Transaksi Pinjaman</h1>
         
    </v-row>
    <center><h5 class="">Jumlah Pendapatan : {{Number(total).toLocaleString()}}</h5></center>
    <div class="tables-basic">
      <v-row>
        <v-col cols=12>
               <v-col cols=4 lg=4 >
                 <v-row >
                    <v-menu
                          ref="date"
                          v-model="date"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                      >
                          <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="pickedDate"
                              label="Tanggal"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              persistent-hint
                              @blur="date = parseDate(dateFormatted)"
                          ></v-text-field>
                          </template>
                          <v-date-picker
                          v-model="pickedDate"
                          @input="fromDate = false"
                          >
                          </v-date-picker>
                    </v-menu>
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="filter"
                    >
                      Filter
                    </v-btn>
                  </v-row>
                  
                  
                </v-col>

                 
            <v-card class="mb-1">
              
            <v-simple-table>
                <thead>
                    <tr>
                        <th class="text-left">No</th>         
                        <th class="text-left">Kolektor</th>  
                        <th class="text-left">No Transaksi</th>  
                        <th class="text-left">Tanggal</th>               
                        <th class="text-left">No Pinjaman</th>
                        <th class="text-left">Nasabah</th>
                        <th class="text-left">Jenis</th>
                        <th class="text-right">Pokok</th>
                        <th class="text-right">Bunga</th>
                        <th class="text-right">Denda</th>
                        <th class="text-right">Total</th>

                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in dataTable" :key="data.notrs">

                        <td>{{ index+1 }}</td>
                        <td>{{ data.kolektor }}</td>
                        <td>{{ data.notrs_bayar }}</td>
                        <td>{{ data.tgl }}</td>
                        <td>{{ data.no_pinjaman }}</td>
                        <td>{{ data.nama_nasabah }}</td>
                        <td>{{ data.periode_pinjam }}</td>
                        <td class="text-right">{{ Number(data.pokok).toLocaleString() }}</td>
                        <td class="text-right">{{ Number(data.bunga).toLocaleString()  }}</td>
                        <td class="text-right">{{ Number(data.denda).toLocaleString() }}</td>
                        <td class="text-right">{{ Number(data.total_bayar).toLocaleString() }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
 
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
export default {
  name: 'TransaksiPinjamanAdmin',
  data () {
    return {
        row: '',
        isShowing:false,
        dataTable:[],
         pickedDate: new Date().toISOString().substr(0, 10),
         date:'',
        total:0,
    }
  },
  computed: {
    ...mapGetters({
          user : 'auth/user',
    }),
  },
  methods: {
    ...mapActions({
        setAlert : 'alert/set',
        setAuth : 'auth/set',
    }),
    status(n){
        if(n == 1) return "Pengajuan"
        if(n == 2) return "Persetujuan"
        if(n == 3) return "Disetujui"
        if(n == 4) return "Pembayaran Kredit"
        if(n == 5) return "Macet"
        if(n == 6) return "Lunas"
    },
    filter(){
      let enddate = new Date(this.pickedDate)
      let formData = {
      'id' : '',
      'tgl' : moment(enddate).format("YYYY-MM-DD")
      // 'row': this.row ,
    }

      this.axios.post('/transaksiPinjamanHarian', formData)
      .then((response) => {
        let { data } = response.data
        this.total=response.data.saldokas
        console.log('total', this.total)
        this.dataTable = data;
      })
      .catch((error) => {
        let responses = error.response
        this.setAlert({
          status : true,
          color : 'error',
          text : responses.data.message,
        })
      })
    }
    
  },
  mounted: function() {
    let now = new Date()
    let formData = {
      'id' : '',
      'tgl' : moment(now).format("YYYY-MM-DD")
      // 'row': this.row ,
    }

    this.axios.post('/transaksiPinjamanHarian', formData)
    .then((response) => {
      let { data } = response.data
      this.total=response.data.saldokas
      console.log('total', this.total)
      this.dataTable = data;
    })
    .catch((error) => {
      let responses = error.response
      this.setAlert({
        status : true,
        color : 'error',
        text : responses.data.message,
      })
    })

     

  }
}

</script>