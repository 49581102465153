<template>
  <v-container fluid>
    <v-row>
        <h1 class="page-title mt-10 mb-6">Daftar Pinjaman</h1>
        <v-col cols=12>
            <v-radio-group
            v-model="row"
            @change="panggilPinjaman"
            row
            >
            <v-radio
                label="Belum Lunas"
                value="2"
            ></v-radio>
            <v-radio
                label="Lunas"
                value="6"
            ></v-radio>
            </v-radio-group>
        </v-col>
    </v-row>
    <div class="tables-basic">
      <v-row>
        <v-col cols=12>
          <v-card class="mb-1">
            <v-simple-table>
                <thead>
                    <tr>
                        <th class="text-center">Aksi</th>
                        <!-- <th class="text-center">No</th>                         -->
                        <th class="text-center">No Pinjaman</th>
                        <th class="text-center">Jenis Pinjaman</th>
                        <th class="text-center">Nama Nasabah</th>
                        <!-- <th class="text-center">Tgl Pinjam</th> -->
                        <th class="text-center">Bayar Terakhir</th>
                        <th class="text-right">Total Pinjam</th>
                        <th class="text-right">Bunga</th>
                        <th class="text-right">Sistem Bunga</th>
                        <th class="text-right">Sisa Pinjaman</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in dataTable" :key="data.notrs">
                        <td>
                            <v-btn
                                color="success"
                                class="text-capitalize button-shadow mr-1"
                                @click="detail(data)"
                                >Detail</v-btn>
                        </td>
                        <td v-show="false" > {{ index+1 }}</td>
                        <td>{{ data.peminjaman_notrs }}</td>
                        <td>{{ data.periodepinjam }}</td>
                        <td>{{ data.nasabah_nama }}</td>
                        <!-- <td>{{ data.peminjaman_tgl }}</td>                         -->
                        <td>{{ data.tglbayarterakhir }}</td>     
                        <td class="text-right">{{ Number(data.peminjaman_total).toLocaleString() }}</td>
                        <td class="text-right">{{ data.peminjaman_bunga }} %</td>
                        <td>{{ (data.peminjaman_sistem=="0" ? "Tetap" : "Menurun") }}</td>
                        <td class="text-right">{{ Number(data.peminjaman_sisa).toLocaleString() }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <br><br>
    <div class="tables-basic history" v-show="isShowing">
        <center><h4 class="">Histori Pembayaran Pinjaman</h4></center>
        <v-row>
            <v-col cols="col-12" lg="4">
                <v-text-field label="No Nasabah - Nasabah"
                              v-model="nasabah"
                              readonly></v-text-field>
                <v-text-field label="Tanggal Pinjam"
                              v-model="tglpinjam"
                            readonly></v-text-field>
                <v-text-field label="Total Pinjaman"
                              v-model="totalpinjam"
                            readonly></v-text-field>
                <v-text-field label="Sistem Bunga"
                              v-model="sistembunga"
                            readonly></v-text-field>
            </v-col>
            <v-col cols="col-12" lg="4"></v-col>
            <v-col cols="col-12" lg="4">
                <v-text-field label="Bunga"
                              v-model="bunga"
                            readonly></v-text-field>
                <v-text-field label="Jangka Waktu (Bln)"
                              v-model="jangkawaktu"
                            readonly></v-text-field>
                <v-text-field label="Sisa Pinjaman"
                              v-model="sisapinjaman"
                            readonly></v-text-field>
            </v-col>
        </v-row>
        <v-card class="mb-1">
        <v-simple-table>
            <thead>
                <tr>
                    <th class="text-left">No</th>
                    <th class="text-left">Tanggal</th>                        
                    <th class="text-right">Pokok</th>
                    <th class="text-right">Bunga</th>
                    <th class="text-right">Denda</th>
                    <th class="text-right">Total Bayar</th>
                    <th class="text-right">Saldo Akhir</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(data, index) in dataPembayaran" :key="data.notrs">
                    <td >{{ index+1 }}</td>
                    <td class="text-left">{{ data.tgl }}</td>
                    <td class="text-right">{{ Number(data.pokok).toLocaleString() }}</td>
                    <td class="text-right">{{ Number(data.bunga).toLocaleString() }}</td>
                    <td class="text-right">{{ Number(data.denda).toLocaleString() }}</td>
                    <td class="text-right">{{ Number(data.totalbayar).toLocaleString() }}</td>
                    <td class="text-right">{{ Number(data.saldoakhir).toLocaleString() }}</td>
                </tr>
            </tbody>
        </v-simple-table>
        </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DaftarKreditur',
  data () {
    return {
        row: '',
        isShowing:false,
        dataTable:[],
        dataPinjaman: [],
        dataPembayaran: [],
        /** form */
        nasabah: "",
        tglpinjam: "",
        totalpinjam: 0,
        sistembunga: 0,
        bunga: 0,
        jangkawaktu: 0,
        sisapinjaman: 0
    }
  },
  computed: {
    ...mapGetters({
          user : 'auth/user',
    }),
  },
  methods: {
    ...mapActions({
        setAlert : 'alert/set',
        setAuth : 'auth/set',
    }),
    status(n){
        if(n == 1) return "Pengajuan"
        if(n == 2) return "Persetujuan"
        if(n == 3) return "Disetujui"
        if(n == 4) return "Pembayaran Kredit"
        if(n == 5) return "Macet"
        if(n == 6) return "Lunas"
    },
    panggilPinjaman(){
      let formData = {
            'status' :this.row,
        }
      this.axios.post('/listDaftarKreditur',formData)
      .then((response) => {
        let { data } = response.data
        this.dataTable = data;
      })
      .catch((error) => {
        let responses = error.response
        this.setAlert({
          status : true,
          color : 'error',
          text : responses.data.message,
        })
      })

    },
    detail(data){
        this.nasabah = data.nasabah_kd+"-"+data.nasabah_nama
        this.tglpinjam = data.peminjaman_tgl
        this.totalpinjam = data.peminjaman_total.toLocaleString()
        this.sistembunga = data.peminjaman_sistem=="0" ? "Tetap" : "Menurun"
        this.bunga = data.peminjaman_bunga+' %'
        this.jangkawaktu = data.peminjaman_lama
        this.sisapinjaman = data.peminjaman_sisa.toLocaleString()
        
        let formData = {
            'notrs' : data.peminjaman_notrs,
        }

        this.axios.post('/listHistoryPembayaran', formData)
        .then((response) => {
                let { data } = response.data
                this.dataPembayaran = data;
                this.isShowing = true
            })
            .catch((error) => {
            let responses = error.response
            this.setAlert({
                status : true,
                color : 'error',
                text : responses.data.message,
            })
        })
    },
  },
  mounted: function() {
     let formData = {
            'status' :this.row,
        }
    this.axios.post('/listDaftarKreditur',formData)
    .then((response) => {
      let { data } = response.data
      this.dataTable = data;
    })
    .catch((error) => {
      let responses = error.response
      this.setAlert({
        status : true,
        color : 'error',
        text : responses.data.message,
      })
    })
  }
}

</script>