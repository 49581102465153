import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/auth';
import alert from '@/store/alert';
import VuexPersist from 'vuex-persist';

// mengatasi refresh pada browser
const vuexPersist = new VuexPersist({
  key: 'my-app',
  storage: localStorage
})

Vue.use(Vuex);

// const token = localStorage.getItem('token');

// if (token) { 
//   this.setHeaderToken(token) 
// } 

export default new Vuex.Store({
  plugins: [vuexPersist.plugin],
  modules: {
    alert,
    auth,
  },
  namespace: true,
  state: {
    prevUrl: '',
    drawer: true
  },
  mutations: {
    setPrevUrl: (state, value) => {
      state.prevUrl = value
    },
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    }
  },
  actions: {
    setPrevUrl: ({commit}, value) => {
      commit('setPrevUrl', value)
    },
    TOGGLE_DRAWER({ commit }) {
      commit('toggleDrawer');
    }
  },
  getters: {
    prevUrl: state => state.prevUrl,
    DRAWER_STATE(state) {
      return state.drawer;
    }
  },
});
