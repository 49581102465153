<template>
  <v-container fluid>
    <v-card-title class="subheading font-weight-bold justify-center">
      Daftar Simpanan
    </v-card-title>
    <div class="tables-basic" v-show="tableShow">
      <v-row>
        <v-col cols=12>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="dataPinjaman"
              :search="search"
            >
              <template v-slot:item.saldo="{ item }">
                
                {{ Number(item.saldo).toLocaleString() }}
              </template>
              <template v-slot:item.aksi="{ item }">
                <v-btn 
                  color='success' 
                  class='text-capitalize button-shadow mr-1' 
                  @click='detail(item)' >
                  Detail
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div class="tables-basic" v-show="tableHistoryShow">
      <v-row>
        <v-col cols=12>
          <v-card>
            <v-card-title>
              <v-menu
                ref="fromDate"
                v-model="fromDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fromPickedDate"
                    label="Tanggal"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    persistent-hint
                    @blur="date = parseDate(dateFormatted)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromPickedDate"
                  @input="fromDate = false"
                >
                </v-date-picker>
              </v-menu>

              <v-menu
                ref="toDate"
                v-model="toDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="toPickedDate"
                    label="Sampai"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    persistent-hint
                    @blur="date = parseDate(dateFormatted)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="toPickedDate"
                  @input="toDate = false"
                >
                </v-date-picker>
              </v-menu>

              <v-spacer></v-spacer>

              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="filter"
              >
                Filter
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                  <v-col cols="col-12" lg="4">
                    <v-row>
                      <v-col cols="col-12" lg="12">
                        <v-text-field prepend-icon="mdi-filter" label="No Nasabah - Nasabah"
                                      v-model="txtNasabah"
                                      disabled></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="col-12" lg="4">
                    <v-row>
                      <v-col cols="col-12" lg="12">
                        <v-text-field prepend-icon="mdi-filter" label="No Tabungan"
                                      v-model="txtNoNasabah"
                                      disabled></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default >
                  <thead>
                  <tr>
                    <th class="text-left pa-6">Tanggal</th>
                    <th class="text-left">Keterangan Rek</th>
                    <th class="text-right">Debet</th>
                    <th class="text-right">Kredit</th>
                    <th class="text-right">Saldo Akhir</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="pa-6">{{ fromPickedDate }}</td>
                      <td>Saldo Awal</td>
                      <td class="text-right">0</td>
                      <td class="text-right" >0</td>
                      <td class="text-right">{{ Number(saldoAwal).toLocaleString() }}</td>
                      <!-- index+1 == 1 ? saldoAwal : (data.debet == 0 ? saldoAkhir+=data.kredit : saldoAkhir-=data.debet) -->
                    </tr>
                    <tr v-for="(data, index) in dataTableSimpanan" :key="data.pk">
                      <td class="pa-6">{{ data.tgl }}</td>
                      <td>{{ data.nm }}</td>
                      <td class="text-right">{{ Number(data.debet).toLocaleString() }}</td>
                      <td class="text-right">{{ Number(data.kredit).toLocaleString() }}</td>
                       
                        <td  class="text-right"  v-if='(index+1) == 1'> {{ data.debet == 0 ? Number(saldoAkhir=parseFloat(saldoAwal)+parseFloat (data.kredit)).toLocaleString() : Number(saldoAkhir=parseFloat (saldoAwal)-parseFloat (data.debet)).toLocaleString() }}</td>
                        <td class="text-right"  v-else>
                          {{ data.debet == 0 ? Number(saldoAkhir+=parseFloat (data.kredit)).toLocaleString() : Number(saldoAkhir-=  parseFloat (data.debet)).toLocaleString() }}
                        </td>
                        
                       
                      <!-- index+1 == 1 ? saldoAwal : (data.debet == 0 ? saldoAkhir+=data.kredit : saldoAkhir-=data.debet) -->
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DataSimpananAdmin',
  data () {
    return {
      tableShow: true,
      tableHistoryShow: false,

      search: '',
      headers: [
          {
            text: 'Aksi',
            align: 'center',
            filterable: false,
            value: 'aksi',
          },
          { text: 'No Simpanan', value: 'no_tabungan' },
          { text: 'Nama Nasabah', value: 'nama_nasabah' },
          { text: 'Jenis', value: 'jenis_tabungan' },
          { text: 'Saldo',align: 'right', value: 'saldo' },
        ],
      dataPinjaman: [],

      fromPickedDate: new Date().toISOString().substr(0, 10),
      toPickedDate: new Date().toISOString().substr(0, 10),
      txtNasabah: "",
      txtNoNasabah: "",
      dataTableSimpanan: [],
      saldoAwal: 0,
      saldoAkhir: 0,
    }
  },
  mounted: function() {
    let formData = {
      'id' : this.user.pk,
    }

    this.axios.post('/getDataSimpanan', formData)
    .then((response) => {
      let { data } = response.data
      this.dataPinjaman = data
    })
    .catch((error) => {
      let responses = error.response
      this.setAlert({
        status : true,
        color : 'error',
        text : responses.data.message,
      })
    })
  },
  computed: {
      ...mapGetters({
          user : 'auth/user',
      }),
  },
  methods: {
    ...mapActions({
      setAlert : 'alert/set',
      setAuth : 'auth/set',
    }),
    detail(item){
      this.tableHistoryShow = true
      this.txtNasabah = item.no_nasabah+"-"+item.nama_nasabah
      this.txtNoNasabah = item.no_tabungan
      this.history()
    },
    filter(){
      this.history()
    },
    history(){
      let formData = new FormData()
      formData.set('selNametabungan', this.txtNoNasabah)
      formData.set('fromDate', this.fromPickedDate)
      formData.set('toDate', this.toPickedDate)
      console.log(this.fromPickedDate)
      
      this.axios.post('/getDataTableSimpanan', formData)
      .then((response) => {
        let { data } = response.data;
        this.dataTableSimpanan = data;

        // hitung saldo awal
        this.axios.post('/getSaldoAwal', formData)
        .then((response) => {
          this.saldoAwal = response.data.saldoAwal;
        })
        .catch((error) => {
          let responses = error.response
          this.setAlert({
            status : true,
            color : 'error',
            text : responses.data.message,
          })
        })
      })
      .catch((error) => {
        let responses = error.response
        this.setAlert({
          status : true,
          color : 'error',
          text : responses.data.message,
        })
      })
    }
  },
}

</script>

