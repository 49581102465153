<template>
  <v-container fluid>
    <v-card-title class="subheading font-weight-bold justify-center">
      Daftar Kredit
    </v-card-title>
    <div class="tables-basic" v-show="tableShow">
      <v-row>
        <v-col cols=12>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="dataPinjaman"
              :search="search"
            >
              <template v-slot:item.peminjaman_sisa="{ item }">
                
                {{ Number(item.peminjaman_sisa).toLocaleString() }}
              </template>
              <template v-slot:item.angsuran="{ item }">
                
                {{ Number(item.angsuran).toLocaleString() }}
              </template>
              <template v-slot:item.aksi="{ item }">
                <v-btn 
                  color='success' 
                  class='text-capitalize button-shadow mr-1' 
                  @click='detail(item)' >
                  Detail
                </v-btn>
                  
              </template>
               
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div class="tables-basic" v-show="tableHistoryShow">
      <v-row>
        <v-col cols=12>
          <v-card>
            
            <v-card-text>
              <v-row>
                  <v-col cols="col-12" lg="3">
                    <v-row>
                      <v-col cols="col-12" lg="12">
                        <v-text-field   label="No Nasabah - Nasabah"
                                      v-model="txtNasabah"
                                      disabled></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="col-12" lg="3">
                    <v-row>
                      <v-col cols="col-12" lg="12">
                        <v-text-field   label="No Pinjaman"
                                      v-model="txtNoNasabah"
                                      disabled></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="col-12" lg="3">
                    <v-row>
                      <v-col cols="col-12" lg="12">
                        <v-text-field   label="Total Pinjaman"
                                      v-model="txttotalpinjaman"
                                      disabled></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="col-12" lg="3">
                    <v-row>
                      <v-col cols="col-12" lg="12">
                        <v-text-field   label="Sisa Pinjaman"
                                      v-model="txtsisapinjaman"
                                      disabled></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>


              </v-row>
            </v-card-text>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default >
                  <thead  >
                  <tr >
                    <th class="text-left pa-6">No</th>
                    <th class="text-left pa-6">Tgl Bayar</th>
                    <th class="text-left">No Bayar</th>
                    <th class="text-right">Pokok</th>
                    <th class="text-right">Bunga</th>
                    <th class="text-right">Denda</th>
                    <th class="text-right">Total Bayar</th>
                  </tr>
                  </thead>
                  <tbody>
                     
                    <tr v-for="(data, index) in dataTableSimpanan" :key="data.pk_bayar">
                      <td class="pa-6">{{index+=1 }}</td>
                      <td class="pa-6">{{ data.tglbayar }}</td>
                      <td>{{ data.notrs_bayar }}</td>
                      <td class="text-right">{{ Number(data.pokok).toLocaleString() }}</td>
                      <td class="text-right">{{ Number(data.bunga).toLocaleString() }}</td>
                      <td class="text-right">{{ Number(data.denda).toLocaleString() }}</td>
                      <td class="text-right">{{ Number(data.total_bayar).toLocaleString() }}</td>
                       
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DataKredit',
  data () {
    return {
      tableShow: true,
      tableHistoryShow: false,

      search: '',
      headers: [
          {
            text: 'Aksi',
            align: 'center',
            filterable: false,
            value: 'aksi',
          },
           
          { text: 'No Pinjaman', value: 'peminjaman_notrs' },
          { text: 'Tgl Pinjam', value: 'peminjaman_tgl' },
          { text: 'Nama Nasabah', value: 'nasabah_nama' },
          { text: 'Jenis', value: 'jenis' },   
          { text: 'Sisa Kredit',align: 'right', value: 'peminjaman_sisa' },
          { text: 'Angsuran',align: 'right', value: 'angsuran' },
          { text: 'Bayar Terakhir', align: 'right',value: 'tglbayarterakhir' },
          { text: 'Terlambat', align: 'center',value: 'telat' },
          
        ],
      dataPinjaman: [],

      fromPickedDate: new Date().toISOString().substr(0, 10),
      toPickedDate: new Date().toISOString().substr(0, 10),
      txtNasabah: "",
      txtNoNasabah: "",
      txttotalpinjaman:0,
      txtsisapinjaman:0,
      txtjenis:'',
      dataTableSimpanan: [],
      saldoAwal: 0,
      saldoAkhir: 0,
    }
  },
  mounted: function() {
    let formData = {
      'id' : this.user.pk,
    }

    this.axios.post('/dataKredit', formData)
    .then((response) => {
      let { data } = response.data
      this.dataPinjaman = data
    })
    .catch((error) => {
      let responses = error.response
      this.setAlert({
        status : true,
        color : 'error',
        text : responses.data.message,
      })
    })
  },
  computed: {
      ...mapGetters({
          user : 'auth/user',
      }),
  },
  methods: {
    ...mapActions({
      setAlert : 'alert/set',
      setAuth : 'auth/set',
    }),
    detail(item){
      this.tableHistoryShow = true
      this.txtNasabah = item.nasabah_kd+"-"+item.nasabah_nama
      this.txtNoNasabah = item.peminjaman_notrs
      this.txttotalpinjaman=Number(item.peminjaman_total).toLocaleString()
      this.txtsisapinjaman=Number(item.peminjaman_sisa).toLocaleString()
      this.txtjenis=item.jenis
      console.log ('jeis',this.txtjenis)
      this.history()
    },
    filter(){
      this.history()
    },
    history(){
      let formData = new FormData()
      formData.set('nopinjaman', this.txtNoNasabah)
      this.axios.post('/getDataTablePembayaran', formData)
      .then((response) => {
        let { data } = response.data;
        this.dataTableSimpanan = data;

      })
      .catch((error) => {
        let responses = error.response
        this.setAlert({
          status : true,
          color : 'error',
          text : responses.data.message,
        })
      })
    }
  },
}

</script>

