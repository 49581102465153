<template>
  <v-container fluid>
    <v-row>
        <h5 class="page-title mt-8 mb-4">Pembayaran Kredit</h5>
         
    </v-row>
    <center><h5 class="">Jumlah Kas : {{total.toLocaleString()}}</h5></center>
    <div class="tables-basic">
      <v-row>
        <v-col cols=12>
          <v-card class="mb-1">
            <v-simple-table>
                <thead>
                    <tr>
                        <th class="text-left">No</th>         
                        <th class="text-left">No Transaksi</th>  
                        <th class="text-left">Tanggal</th>               
                        <th class="text-left">No Pinjaman</th>
                        <th class="text-left">Nasabah</th>
                        <th class="text-left">Jenis</th>
                        <th class="text-left">Angsuran ke</th>
                        <th class="text-right">Pokok</th>
                        <th class="text-right">Bunga</th>
                        <th class="text-right">Denda</th>
                        <th class="text-right">Total</th>

                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in dataTable" :key="data.notrs">

                        <td>{{ index+1 }}</td>
                        <td>{{ data.notrs_bayar }}</td>
                        <td>{{ data.tgl }}</td>
                        <td>{{ data.no_pinjaman }}</td>
                        <td>{{ data.nama_nasabah }}</td>
                        <td>{{ data.periode_pinjam }}</td>
                        <td>{{ data.angsuran }}</td>
                        <td class="text-right">{{ data.pokok.toLocaleString() }}</td>
                        <td class="text-right">{{ data.bunga.toLocaleString()  }}</td>
                        <td class="text-right">{{ data.denda.toLocaleString() }}</td>
                        <td class="text-right">{{ data.total_bayar.toLocaleString() }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
 
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
export default {
  name: 'transaksiharianpinjaman',
  data () {
    return {
        row: '',
        isShowing:false,
        dataTable:[],
         
        total:0,
    }
  },
  computed: {
    ...mapGetters({
          user : 'auth/user',
    }),
  },
  methods: {
    ...mapActions({
        setAlert : 'alert/set',
        setAuth : 'auth/set',
    }),
    status(n){
        if(n == 1) return "Pengajuan"
        if(n == 2) return "Persetujuan"
        if(n == 3) return "Disetujui"
        if(n == 4) return "Pembayaran Kredit"
        if(n == 5) return "Macet"
        if(n == 6) return "Lunas"
    },
    
  },
  mounted: function() {
    let now = new Date()
    let formData = {
      'id' : this.user.pk,
      'tgl' : moment(now).format("YYYY-MM-DD")
      // 'row': this.row ,
    }

    this.axios.post('/transaksiPinjamanHarian', formData)
    .then((response) => {
      let { data } = response.data
      this.total=response.data.saldokas
      console.log('total', this.total)
      this.dataTable = data;
    })
    .catch((error) => {
      let responses = error.response
      this.setAlert({
        status : true,
        color : 'error',
        text : responses.data.message,
      })
    })

     

  }
}

</script>