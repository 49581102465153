<template>
  <v-container fluid>
    <h1 class="page-title mt-10 mb-6">Transaksi Simpanan</h1>
    <v-row>
        <v-col md=12>
            <v-card class="mb-1">
                <v-card-text>
                    <v-form ref="form" lazy-validation> 
                        <v-row>
                            <v-col cols="col-12" lg="4">
                                <v-combobox
                                    v-model="selNasabah"
                                    item-text="tabungan"
                                    item-value="notabungan"
                                    :items="itemNasabah"
                                    :search-input.sync="search"
                                    @change="selectedOption"
                                    label="Nasabah"
                                    prepend-icon="mdi-magnify"
                                    outlined
                                    dense>
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                No results matching "<strong>{{ search }}</strong>"
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                                
                                <div v-show="formShow">
                                    <v-col>
                                        <input type="hidden" name="pk_nasabah" class="form-control" :value="pk_nasabah">
                                        <input type="hidden" name="nama_nasabah" class="form-control" :value="nama_nasabah">
                                        <input type="hidden" name="pk_tabungan" class="form-control" :value="pk_tabungan">
                                        <input type="hidden" name="jenistabungan" class="form-control" :value="jenistabungan">
                                        <input type="hidden" name="no_tabungan" class="form-control" :value="no_tabungan">
                                        
                                        <v-text-field 
                                            v-model="txtNoSimpan"
                                            prepend-icon="mdi-account-cash" 
                                            label="No Simpanan" 
                                            disabled>
                                        </v-text-field>
                                        <v-text-field 
                                            v-model="txtSaldoAwal"
                                            prepend-icon="mdi-wallet" 
                                            label="Saldo Awal"
                                            disabled>
                                        </v-text-field>
                                        <v-row>
                                            <v-col cols="col-12" lg="6">
                                                <v-text-field 
                                                    v-model="txtSetor"
                                                    :disabled="disabledSetor"
                                                    prepend-icon="mdi-tray-plus"
                                                    label="Setoran">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="col-12" lg="6">
                                                <v-text-field 
                                                    v-model="txtTarik"
                                                    :disabled="disabledTarik"
                                                    prepend-icon="mdi-tray-minus" 
                                                    label="Penarikan">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-text-field 
                                            v-model="txtSaldoAkhir"
                                            prepend-icon="mdi-wallet" 
                                            label="Saldo Akhir">
                                        </v-text-field>
                                        <v-btn
                                            color="success"
                                            class="text-capitalize button-shadow mr-1"
                                            @click="simpan"
                                            >Simpan</v-btn>
                                        <v-btn
                                            color="success"
                                            class="text-capitalize button-shadow mr-1"
                                            @click="cetak"
                                            >Cetak</v-btn>
                                    </v-col>
                                </div>
                            </v-col>
                            
                            <v-col cols="col-12" v-show="printShow"> 
                                       <iframe  id="blue" src="iframe.html"
        sandbox="allow-scripts allow-modals">                        
                                <div id="print">
                                    <v-col>
                                        <table>
                                            
                                            <tr>
                                                <td colspan="3">
                                                    <center> Bukti Transaksi</center> 

                                                    <center>KSP Putra Darma Citra</center>
                                                </td>
                                            </tr>
                                                                                        
                                            <tr>
                                                <td colspan="3">
                                                    Tanggal : {{ this.nowDate }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="3">
                                                    ===============================
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    No Tabungan
                                                </td>
                                                <td style='width: 30px;' >
                                                    :
                                                </td>
                                                <td>
                                                    {{ this.no_tabungan }}
                                                </td>
                                            </tr>                                            
                                            <tr>
                                                <td>
                                                    Nama Nasabah
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td>
                                                    {{ this.nama_nasabah }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Saldo Awal
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td style="text-align:right">
                                                    {{ this.txtSaldoAwal.toLocaleString() }}
                                                </td>
                                            </tr>                                            
                                            <tr v-if="this.txtSetor === 0 && this.txtTarik !== 0">
                                                <td>
                                                    Penarikan
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td style="text-align:right">
                                                    {{ parseInt(this.txtTarik).toLocaleString() }}
                                                </td>
                                            </tr>                                                                                  
                                            <tr v-if="this.txtTarik === 0 && this.txtSetor !== 0">
                                                <td>
                                                    Setoran
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td style="text-align:right">
                                                    {{ parseInt(this.txtSetor).toLocaleString() }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Saldo Akhir
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td style="text-align:right">
                                                    {{ this.txtSaldoAkhir.toLocaleString() }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="3">
                                                    ===============================
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Kolektor
                                                    <br>
                                                    <br>
                                                    {{username}}
                                                </td>
                                                <td>
                                                </td>
                                                <td style="text-align:center">
                                                    Nasabah
                                                    <br>
                                                    <br>
                                                    -------------
                                                </td>
                                            </tr>
                                        </table>
                                    </v-col>
                                </div>
                                </iframe>
                            </v-col>
                            
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <div class="tables-basic" v-show="tableShow">
      <v-row>
        <v-col cols=12>
          <v-card class="mb-1">  
              <v-card-title class="subheading font-weight-bold justify-center">
                <center>
                    <h2>Transaksi Simpanan</h2>
                    <h6>{{ selNasabah.tabungan }}</h6>
                </center>
              </v-card-title>
            <v-simple-table>
                <thead>
                    <tr>
                        <!-- <th class="text-center">Aksi</th> -->
                        <th class="text-center">No Simpanan</th>
                        <th class="text-center">Nama</th>
                        <th class="text-center">Jenis</th>
                        <th class="text-right">Setor</th>
                        <th class="text-right">Tarik</th>
                        <th class="text-right">Saldo Akhir</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- <tr v-for="(data ,i ) in dataTableTransSimpanan" :key="data.dt_pk"> -->

                        <!-- <td>
                            <v-btn v-if="(dataTableTransSimpanan.length -1 ) == i"
                                color="warning"
                                class="text-capitalize button-shadow mr-1"
                                @click="hapus"
                                >Hapus</v-btn>
                        </td> -->
                        <tr v-for="data  in dataTableTransSimpanan" :key="data.dt_pk"> 

                        <td>{{ data.no_tabungan }}</td>
                        <td>{{ data.nama_nasabah }}</td>
                        <td>{{ data.jenis_tabungan }}</td>
                        <td class="text-right" >{{ Number(data.setor).toLocaleString() }}</td>
                        <td class="text-right">{{ Number(data.tarik).toLocaleString() }}</td>
                        <td class="text-right">{{ Number(data.saldo_akhir).toLocaleString() }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

export default {
  name: 'TransaksiSimpanan',
  data () {
    return {
        gettingLocation: false,
        selNasabah: "",
        itemNasabah: [],
        search: null,
        formShow: false,
        tableShow: false,
        printShow: false,
        dataTableTransSimpanan : [],
        /** hidden value */
        pk_nasabah: "", 
        nama_nasabah: "", 
        pk_tabungan: "", 
        jenistabungan: "", 
        no_tabungan: "",
        /** end */
        txtNoSimpan: "",
        txtSaldoAwal: 0,
        txtSetor: 0,
        disabledSetor: false,
        txtTarik: 0,
        disabledTarik: false,
        txtSaldoAkhir: 0,
        nowDate: moment(new Date()).format("YYYY-MM-DD"),
        username:'',
    }
  }, 
    watch: {
        search:function(){
            this.searchNasabah()
        },
        txtSetor:function(){
            if(this.txtSetor>0 && this.txtTarik<1){
                this.txtTarik=0
                this.disabledTarik = true
                this.tambahSaldoAkhir()
            } else {
                this.disabledTarik = false
                this.txtTarik=0
                this.txtSaldoAkhir=0
            }
        },
        txtTarik:function(){
            if(this.txtTarik>0 && this.txtSetor<1){                
                this.txtSetor=0
                this.disabledSetor = true
                this.kurangSaldoAkhir()
            } else {
                this.disabledSetor = false
                this.txtSetor=0
                this.txtSaldoAkhir=0
            }
        },
    },
    computed: {
        ...mapGetters({
            user : 'auth/user',
        }),
    },
  methods: {
    ...mapActions({
        setAlert : 'alert/set',
        setAuth : 'auth/set',
    }),
    tambahSaldoAkhir(){
           this.txtSaldoAkhir  = parseInt(this.txtSaldoAwal) + parseInt(this.txtSetor)
    },
    kurangSaldoAkhir(){
         this.txtSaldoAkhir = parseInt(this.txtSaldoAwal) - parseInt(this.txtTarik)
    },
    searchNasabah(){
        let keyword = this.search
        if(keyword.length>0){
            this.axios.get('/searchNasabah/'+keyword)
            .then((response) => {
                let { data } = response.data
                this.itemNasabah = data;
            })
            .catch((error) => {
                console.log(error)
            })
        }else{
            this.itemNasabah = []
        }
    },
    selectedOption(){
        let keyword = this.selNasabah.no_tabungan
        if(keyword.length>0){
            this.axios.get('/getTabungan/'+keyword)
            .then((response) => {
                let { data } = response.data
                this.itemNasabah = data;
                this.pk_nasabah = this.itemNasabah[0].pk_nasabah, 
                this.nama_nasabah = this.itemNasabah[0].nama_nasabah, 
                this.pk_tabungan = this.itemNasabah[0].pk_tabungan, 
                this.jenistabungan = this.itemNasabah[0].jenistabungan, 
                this.no_tabungan = this.itemNasabah[0].no_tabungan,
                this.txtNoSimpan = this.itemNasabah[0].no_tabungan+" | "+this.itemNasabah[0].tabungan
                this.txtSaldoAwal = this.itemNasabah[0].saldoakhir_tabungan
                
                this.formShow = true

                let formData = new FormData()
                formData.set('notabungan', this.itemNasabah[0].no_tabungan)

                this.axios.post('/getDataTableTransSimpanan', formData)
                .then((response) => {
                    let { data } = response.data;
                    this.dataTableTransSimpanan = data;

                    this.tableShow = true
                })
                .catch((error) => {
                    let responses = error.response
                    this.setAlert({
                    status : true,
                    color : 'error',
                    text : responses.data.message,
                    })
                })
            })
            .catch((error) => {
                console.log(error)
            })
        }else{
            this.itemNasabah = []
        }
    },
    simpan(){
        if(this.txtSetor !== 0 || this.txtTarik !== 0){
            let now = new Date()

            let formData = new FormData()
            
            formData.set('user_id', this.user.pk)
            formData.set('pk_nasabah', this.pk_nasabah)
            formData.set('nama_nasabah', this.nama_nasabah)
            formData.set('pk_tabungan', this.pk_tabungan)
            formData.set('jenistabungan', this.jenistabungan)
            formData.set('no_tabungan', this.no_tabungan)
            formData.set('notrs', moment(now).format("YYMMDDHHmmss"))
            formData.set('date', moment(now).format("YYYY-MM-DD HH:mm:ss"))
            formData.set('txtSaldoAwal', this.txtSaldoAwal)
            formData.set('txtSetor', this.txtSetor)
            formData.set('txtTarik', this.txtTarik)
            formData.set('txtSaldoAkhir', this.txtSaldoAkhir)
            formData.set('latitude', this.location.coords.latitude)
            formData.set('longtitude', this.location.coords.longitude)

            this.axios.post('/insertSimpanan', formData)
            .then((response) => {
                if(response.data.status == "success"){
                    this.setAlert({
                        status : true,
                        color : 'success',
                        text : response.data.message,
                    })

                    this.selectedOption()
                    this.txtTarik=0
                    this.txtSetor=0
                    this.cetak()
                }
            })
            .catch((error) => {
                let responses = error.response
                this.setAlert({
                    status : true,
                    color : 'error',
                    text : responses.data.message,
                })
            })
        } else {
            this.setAlert({
                status : true,
                color : 'error',
                text : "Masukan Jumlah Setoran atau Penarkan !",
            })
        }
    },
    cetak(){
        let divToPrint = document.getElementById('print');
        let htmlToPrint = '' +
                '<style type="text/css">' +
                '@media print { ' +
                '@page {' +
                    'size: statement;' +
                '}'+
                'html, body {' +
                    'width: 58mm;' +
                '}'+
                '}' +
                '</style>';
        htmlToPrint += divToPrint.outerHTML;
        let newWin = window.open("");
        newWin.document.write(htmlToPrint);
        newWin.print();
        newWin.close();
    },
    hapus(){},
  },
  created() {
      //do we support geolocation
      this.username=this.user.username;
    if(!("geolocation" in navigator)) {
      this.errorStr = 'Geolocation is not available.';
      return;
    }

    this.gettingLocation = true;
    // get position
    navigator.geolocation.getCurrentPosition(pos => {
      this.gettingLocation = false;
      this.location = pos;
      console.log(this.location)
    }, err => {
      this.gettingLocation = false;
      this.errorStr = err.message;
    })
  }
}

</script>
