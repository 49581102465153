import Vue from 'vue'
import './plugins/axios'
import moment from 'moment'
import App from './App.vue'
import store from './store/index'
// import auth from './store/auth';
import router from './Routes'
import vuetify from './plugins/vuetify'
import './plugins/helper'
import './registerServiceWorker'

// import { setHeaderToken } from '../utils/auth'

Vue.config.productionTip = false

// const token = localStorage.getItem('token');

// if (token) { 
//   this.setHeaderToken(token) 
// } 

// store.dispatch('get_user', token)
// .then(() => {
  new Vue({
    vuetify,
    moment,
    router,
    render: h => h(App), store
  }).$mount('#app')
// }).catch((error) => {
//   console.error(error);
// })
